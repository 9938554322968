var i18n_en = {
  // draw screen
  'Lucky Draw': 'Lucky Draw',
  'X Prize': 'X Prize',
  'Diamond Prize': 'Diamond Prize',
  'Gold Prize': 'Gold Prize',
  'Silver Prize': 'Silver Prize',
  'Silver Prize': 'Silver Prize',
  'Bronze Prize': 'Bronze Prize',
  'Press the Spin button to start': 'Press the Spin button to start',
  'Press the Presentation button to start': 'Press the button below to start the show',
  'Winner is coming...': 'Winner is coming...',
  'Spin': 'Spin',
  'Stop': 'Stop',
  'Confirm': 'Confirm',
  'Retry': 'Reject',
  'Customize': 'Customize',
  'Register Now': 'Register',
  'Buy Now': 'Buy Now',
  'Presentation': 'Presentation',
  'Draw Now': 'Draw Now',
  'Amazing Event': 'Lucky Draw Software',
  'Your Event Name': 'Your event name',
  'Winner Name Here': 'Winner Name Here',

  'Save': 'Save',
  'Cancel': 'Cancel',
  'Reset Default': 'Reset',

  // edit ID
  'Edit ID': 'My ID List',
  'Update IDs for Presentation': 'You Need A Few IDs To Start Lucky Drawing',
  'Your Drawing IDs': 'Update IDs',
  'Or Auto Generate ID': 'or auto generate',
  'Generate': 'Generate',
  'Total': 'Total',
  'IDs': 'IDs',
  'IDs ( 0-9, A-Z, maximum 16 characters )': 'STEP 1: Paste your ID list here',
  'ID max length exceeds': 'A single ID can not exceeds 23 characters',
  'Name List': 'STEP 2: Paste your Name list here',
  'Names': 'Names',
  'ID/Name pairs will be automatically matched line by line': '',
  'Copy Paste lists of IDs/ Names in to corresponding column': '',
  'You’ve reached 15 IDs limit': 'Free account is limited to 3 trial drawings!',
  'Upgrade to Remove Your Account Limits': '',
  'Upgrade to Continue Lucky Drawing': '',
  'Pay to Extend Usage Time': 'Pay Now to Extend Usage Time For Your Next Event',
  'Pay to Extend Lucky Drawing Time': 'Pay Now to Save Drawing Results',
  'Usage Days': 'Unlimited lucky drawing results for',
  'per Event': 'per Event',
  'Service Price Guide': '',
  'Upgrade Account Now': 'Upgrade Account Now',
  'Return to editing': 'Edit my ID list',
  'Upgrade Account': 'Upgrade Account',
  'WePay Payment Guide': 'You will be redirected to the WePay website to complete your secure payment.',
  '1Pay Payment Guide': 'You will be redirected to the 1Pay website to complete your secure payment.',
  'Paypal Payment Guide': 'You will be redirected to the Paypal website to complete your secure payment.',
  '1 day event': '1 day event',
  '3 days event': '3 days event',
  '5 days event': '5 days event',
  '24 hours': '24 hours',
  '3 days': '3 days',
  '5 days': '5 days',
  'event1day': '+2 days free',
  'event3days': '+3 days free',
  'event5days': '+4 days free',
  'for pre-event testing': 'for pre-event testing',
  'estimated usage time': 'Estimated usage time:',
  'until': 'until',
  'Please contact LuckyDraw to manually activate your payment': 'Please contact LuckyDraw to manually activate your payment',

  // payment success
  'Payment Complete': 'Payment Completed',
  'Payment completed successfully': "You've Upgraded Your Account Successfully",
  'Thanks for being awesome with Lucky Draw': 'Thank You For Being Awesome with Lucky Draw!',
  'Your subscription': 'Valid until',
  'Now you’ll return to edit your custom Photos/ IDs': '',

  // payment failure
  'Unable to init payment process. Please try again.': 'Unable to init payment process. Please try again.',

  // cusomize
  'Customize Action Message': 'Customize Messages',
  'Customize name of Prizes': 'Customize Prizes',
  'Customize Button': 'Customize Buttons',
  'Select Checkbox to Enable Prize': 'Hint: Select check boxes to enable the prizes.<br />Click the icons on the right to upload replacements.',

  // links
  'Pricing': 'Pricing',

  // result
  'Result': 'Result',
  'Rejected': 'Rejected',
  'Confirmed': 'Confirmed',
  'ID Drawing Winner': 'Lucky IDs',
  'Drawing Session': 'Session',
  'This will delete all prizes in session': 'This will delete all prizes in session',
  'or': 'or',
  'Draw Date': 'Date',
  'Winner Name': 'Name',
  'No result': '-',
  'Download Results': 'Download',
  'Diamond': 'Diamond',
  'Gold': 'Gold',
  'Silver': 'Silver',
  'Bronze': 'Bronze',
  'X-Prize': 'X Prize',
  'Everybody Win!': 'No IDs available to draw. All IDs are either won or rejected.',
  'Prize': 'Prize',
  'Prize 4': 'Prize 4',
  'Prize 5': 'Prize 5',
  'Prize 6': 'Prize 6',
  'Prize 7': 'Prize 7',
  'Prize 8': 'Prize 8',
  'Prize 9': 'Prize 9',
  'Prize 10': 'Prize 10',
  'Prize 11': 'Prize 11',
  'Prize 12': 'Prize 12',
  'Prize 13': 'Prize 13',
  'Prize 14': 'Prize 14',
  'Prize 15': 'Prize 15',
  'Prize 16': 'Prize 16',
  'Prize 17': 'Prize 17',
  'Prize 18': 'Prize 18',
  'Prize 19': 'Prize 19',
  'Prize 20': 'Prize 20',
  'Prize 21': 'Prize 21',
  'Prize 22': 'Prize 22',
  'Prize 23': 'Prize 23',

  // introduction
  'How-to': 'How-to',
  'Introduction': 'Introduction',
  'About Us': 'About Us',
  'FAQ': 'FAQ',
  'Term of Service': 'Terms of Service',
  'Privacy Policy': 'Privacy Policy',
  'Contact and Support': 'Contact and Support',
  'templates/tab-about.html': 'templates/tab-about.html',
  'templates/tab-faq.html': 'templates/tab-faq.html',
  'templates/tab-term.html': 'templates/tab-term.html',
  'templates/tab-policy.html': 'templates/tab-policy.html',
  'templates/tab-contact.html': 'templates/tab-contact.html',

  // login
  'Login': 'Login',
  'Email': 'Email',
  'Password': 'Password',
  'Not a member': 'Not a member',
  'Sign up now': 'Sign up now',
  "Don't have an account?": "Don't have an account?",
  'Account is being used': 'Your account is being used somewhere else',
  'Someone logged-in somewhere!': "Your account is being logged-in on a different device elsewhere. To keep your account's data safe, please only log-in on one device at a time.",
  'Login to continue': 'Login to continue',

  // register
  'Register': 'Register',
  'Company': 'Company',
  'Your Name': 'Your Name',
  'ABC Company': 'enter your name',
  'abcd@gmail.com': 'enter your email',
  'Send me LuckyDraw newsletters': 'Send me LuckyDraw newsletters (3 emails / year)',
  'Return to Login form': 'Return to Login form',
  'Return to': 'Return to',
  'Login form': 'Login form',
  'Already have an account?': 'Already have an account?',
  'Login / Register': 'Login',
  'You can create your own lucky drawing list after registration': 'You can create your own lucky drawing list after registration',

  // forgot password
  'Forgot password': 'Forgot password',
  'Send email': 'Send email',
  'Reset Password': 'Reset Password',
  'Please check your email for reset password instruction': 'Please check your inbox for an email we just sent you with instructions for how to reset your password and log into your account.',
  'Enter your registered email': 'Enter your registered email',

  // logout
  'Logout': 'Logout',

  'Exit': 'Exit',
  'Finish': 'Finish',

  // account info
  'Account Info': 'Account',
  'Account information': 'My Account',
  'Account Type': 'Account Type',
  'Paid Account': 'Paid',
  'Free Account': 'Free',
  'Expired Account': 'Expired',
  'Last Payment': 'Last Payment',
  'Expiration Date': 'Expiration Date',
  'Change Password': 'Change Password',
  'Current password': 'Current Password',
  'New password': 'New Password',
  'Re-enter': 'Re-enter Password',
  'Logo': 'Logo',
  'Pay to Extend': 'Extend Usage Time',
  'Pay to Upgrade': 'Upgrade Account',
  'Pay Now': 'Pay Now',

  // theme
  'Change theme': 'LuckyDraw Theme',
  'Select a color theme that match your brand or event': 'Select a color theme that match your brand',

  // language
  'Change language': 'Language',

  // background
  'Change Background': 'Change Background',
  'Upload New Background': 'Upload New',
  'Remove Background': 'Remove Background',

  // contact us
  'Contact us': 'Contact us',
  'Contact Name': 'Your Name',
  'Inquiry': 'Message',
  'Submit': 'Submit',
  'Your message has been sent successfully to LuckyDraw team!': 'Your message has been sent successfully to LuckyDraw team!',
  'Unable to send your message': 'It looks like an unexpected technical issue is preventing the submission of your form. Please send you request via email to hi@luckydraw.live instead.',

  // theme
  'Theme Purple': 'Purple',
  'Theme Navy': 'Navy',
  'Theme Olive': 'Olive',
  'Theme Orange': 'Orange',
  'Theme Black': 'Black',
  'Theme Blue': 'Blue',
  'Theme Green': 'Green',
  'Theme Red': 'Red',
  'Theme Silver': 'Silver',
  'Theme Aqua': 'Aqua',
  'Theme Yellow': 'Yellow',
  'Theme White': 'White',

  // date time format
  'DateTimeFormat': 'MMM Do YYYY, HH:mm:ss',
  'shortDateTimeFormat': 'MMM Do YYYY',

  // alert
  'Done': 'Done',
  'Info': 'Info',

  // guide
  'guide_shortcut_Enter': 'Hint: press Enter on the keyboard to Spin',
  'guide_shortcut_EnterAgain': 'Press Enter again to Stop',
  'guide_shortcut_AcceptCancel': 'Press + or Space key to Accept; - or Del to Cancel',
  'guide_shortcut_LeftRight': 'Press Left/Right or 4/6 keys to switch prize',
  'guide_Completed': "You're Awesome! Now login to upload your own drawing IDs.",
  'Reload to Update New Version': 'LuckyDraw updates available! Please press Ctrl+F5 to reload the website with latest features.',
  'Please open customize page with WiFi': 'No Internet connection available! Please visit this page while being connected to the Internet to load your data first, otherwise the offline mode will not work.',
  'Not Editable When Disconnected': 'Please connect to the Internet to edit data.',
  'Not Doable When Disconnected': 'Please connect to the Internet for this function to work.',
  'Disconnected': 'You have no Internet connection available!',
  'Only Switch to Presentation when Online': 'Please connect to the Internet first to switch to Presentation mode. After that you can disconnect and perform lucky drawing in offline mode.',
  'Only Exit Presentation when Online': 'Unable to exit Presentation mode while working offline.'
}
