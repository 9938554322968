var i18n_vi = {
  // draw screen
  'Lucky Draw': 'Quay số trúng thưởng',
  'X Prize': 'Giải X',
  'Diamond Prize': 'Giải Đặc Biệt',
  'Gold Prize': 'Giải Nhất',
  'Silver Prize': 'Giải Nhì',
  'Silver Prize': 'Giải Nhì',
  'Bronze Prize': 'Giải Ba',
  'Press the Spin button to start': 'Nhấn nút Quay số để bắt đầu',
  'Press the Presentation button to start': 'Nhấn Trình chiếu để bắt đầu sự kiện',
  'Winner is coming...': 'Đang tìm người may mắn...',
  'Spin': 'Quay số',
  'Stop': 'Chốt',
  'Confirm': 'Xác nhận',
  'Retry': 'Quay lại',
  'Customize': 'Chỉnh sửa',
  'Register Now': 'Đăng ký',
  'Buy Now': 'Mua Phần Mềm',
  'Presentation': 'Trình chiếu!',
  'Draw Now': 'Quay Số',
  'Amazing Event': 'Lucky Draw Software',
  'Your Event Name': 'Tên sự kiện',
  'Winner Name Here': 'Người May Mắn',

  'Save': 'Lưu lại',
  'Cancel': 'Hủy bỏ',
  'Reset Default': 'Mặc định',

  // edit ID
  'Edit ID': 'Danh sách mã quay thưởng của tôi',
  'Update IDs for Presentation': 'Nhập danh sách mã số để quay thưởng',
  'Your Drawing IDs': 'Cập nhật danh sách',
  'Or Auto Generate ID': 'hoặc tự động tạo số',
  'Generate': 'Tạo số',
  'Total': 'Số lượng',
  'IDs': 'Mã số',
  'IDs ( 0-9, A-Z, maximum 16 characters )': 'BƯỚC 1: Copy danh sách Mã số quay thưởng vào đây',
  'ID max length exceeds': 'Mỗi mã quay thưởng không được vượt quá 23 ký tự',
  'Name List': 'BƯỚC 2: Copy danh sách Họ tên vào đây',
  'Names': 'Họ tên',
  'ID/Name pairs will be automatically matched line by line': '',
  'Copy Paste lists of IDs/ Names in to corresponding column': '',
  'You’ve reached 15 IDs limit': 'Tài khoản thử nghiệm bị giới hạn 3 lần quay ở chế độ Trình chiếu!',
  'Upgrade to Remove Your Account Limits': '',
  'Upgrade to Continue Lucky Drawing': '',
  'Pay to Extend Usage Time': 'Gia hạn thời gian sử dụng cho sự kiện tiếp theo của bạn',
  'Pay to Extend Lucky Drawing Time': 'Gia hạn để lưu được kết quả quay thưởng',
  'Usage Days': 'Quay thưởng không giới hạn trong',
  'per Event': '/ sự kiện',
  'Service Price Guide': '',
  'Upgrade Account Now': 'Nâng cấp tài khoản',
  'Return to editing': 'Danh sách mã quay thưởng của tôi',
  'Upgrade Account': 'Nâng cấp tài khoản',
  'WePay Payment Guide': "Bạn sẽ được chuyển sang cổng thanh toán WePay để thực hiện thanh toán an toàn. Tại trang sohapay.vn, nhấn vào mục 'Thanh toán không cần đăng nhập' để thanh toán nhanh. Tài khoản của bạn sẽ được tự động nâng cấp ngay sau khi hoàn tất giao dịch.",
  '1Pay Payment Guide': 'Bạn sẽ được chuyển sang cổng thanh toán 1Pay để thực hiện thanh toán an toàn. Tài khoản của bạn sẽ được tự động nâng cấp ngay sau khi hoàn tất giao dịch.',
  'Paypal Payment Guide': 'Bạn sẽ được chuyển sang cổng thanh toán PayPal để thực hiện thanh toán an toàn. Tài khoản của bạn sẽ được tự động nâng cấp ngay sau khi hoàn tất giao dịch.',
  '1 day event': 'Event 1 ngày',
  '3 days event': 'Event 3 ngày',
  '5 days event': 'Event 5 ngày',
  '24 hours': '24 giờ',
  '3 days': '3 ngày',
  '5 days': '5 ngày',
  'event1day': 'Tặng 2 ngày',
  'event3days': 'Tặng 3 ngày',
  'event5days': 'Tặng 4 ngày',
  'for pre-event testing': 'sử dụng miễn phí để chuẩn bị trước sự kiện',
  'estimated usage time': 'Thời hạn sử dụng (ước tính):',
  'until': 'đến',
  'Please contact LuckyDraw to manually activate your payment': 'Vui lòng liên hệ theo số 0904.131.696 để hoàn tất giao dịch của bạn',

  // payment success
  'Payment Complete': 'Thanh toán thành công',
  'Payment completed successfully': 'Bạn đã thanh toán và nâng cấp tài khoản thành công',
  'Thanks for being awesome with Lucky Draw': 'Cảm ơn bạn đã sử dụng LuckyDraw!',
  'Your subscription': 'Ngày nâng cấp tiếp theo',
  'Now you’ll return to edit your custom Photos/ IDs': '',

  // payment failure
  'Unable to init payment process. Please try again.': 'Không kết nối được tới cổng thanh toán. Vui lòng nhấn F5 và thử lại!',

  // cusomize
  'Customize Action Message': 'Chỉnh sửa thông báo',
  'Customize name of Prizes': 'Đổi tên giải thưởng',
  'Customize Button': 'Đổi tên nút bấm',
  'Select Checkbox to Enable Prize': 'Hướng dẫn: Đánh dấu chọn để sử dụng các cơ cấu giải.<br />Nhấn vào biểu tượng giải thưởng để thay thế hình ảnh.',

  // links
  'Pricing': 'Phí sử dụng',

  // result
  'Result': 'Kết quả',
  'Rejected': 'Bỏ qua',
  'Confirmed': 'Đã xác nhận',
  'ID Drawing Winner': 'Kết quả quay số may mắn',
  'Drawing Session': 'Phiên quay thưởng',
  'This will delete all prizes in session': 'Xóa toàn bộ kết quả trong phiên',
  'or': 'hay',
  'Draw Date': 'Ngày',
  'Winner Name': 'Họ tên',
  'No result': '-',
  'Download Results': 'Download',
  'Diamond': 'Đặc biệt',
  'Gold': 'Giải Nhất',
  'Silver': 'Giải Nhì',
  'Bronze': 'Giải Ba',
  'X-Prize': 'Giải X',
  'Everybody Win!': 'Không còn mã số để quay thưởng. Toàn bộ mã số hoặc đã trúng thưởng, hoặc đã bị loại.',
  'Prize': 'Giải',
  'Prize 4': 'Giải 4',
  'Prize 5': 'Giải 5',
  'Prize 6': 'Giải 6',
  'Prize 7': 'Giải 7',
  'Prize 8': 'Giải 8',
  'Prize 9': 'Giải 9',
  'Prize 10': 'Giải 10',
  'Prize 11': 'Giải 11',
  'Prize 12': 'Giải 12',
  'Prize 13': 'Giải 13',
  'Prize 14': 'Giải 14',
  'Prize 15': 'Giải 15',
  'Prize 16': 'Giải 16',
  'Prize 17': 'Giải 17',
  'Prize 18': 'Giải 18',
  'Prize 19': 'Giải 19',
  'Prize 20': 'Giải 20',
  'Prize 21': 'Giải 21',
  'Prize 22': 'Giải 22',
  'Prize 23': 'Giải 23',

  // introduction
  'How-to': 'Hướng dẫn',
  'Introduction': 'Giới thiệu', // "Hướng dẫn",
  'About Us': 'Về LuckyDraw.live', // "LuckyDraw",
  'FAQ': 'Câu hỏi thường gặp', // "Câu hỏi thường gặp",
  'Term of Service': 'Quy định sử dụng', // "Quy định sử dụng",
  'Privacy Policy': 'Chính sách bảo mật', // "Chính sách bảo mật",
  'Contact and Support': 'Liên hệ và Hỗ trợ', // "Liên hệ và Hỗ trợ",
  'templates/tab-about.html': 'templates/vi/tab-about.html',
  'templates/tab-faq.html': 'templates/vi/tab-faq.html',
  'templates/tab-term.html': 'templates/tab-term.html',
  'templates/tab-policy.html': 'templates/tab-policy.html',
  'templates/tab-contact.html': 'templates/tab-contact.html',

  // login
  'Login': 'Đăng nhập',
  'Email': 'Email',
  'Password': 'Mật khẩu',
  'Not a member': 'Bạn chưa có tài khoản',
  'Sign up now': 'Đăng ký ngay',
  "Don't have an account?": '',
  'Account is being used': 'Tài khoản đang được sử dụng ở một nơi khác',
  'Someone logged-in somewhere!': 'Tài khoản của bạn đã được đăng nhập và sử dụng ở một nơi khác. Để đảm bảo an toàn dữ liệu, vui lòng chỉ đăng nhập tài khoản trên một máy tính tại một thời điểm.',
  'Login to continue': 'Đăng nhập để tiếp tục',

  // register
  'Register': 'Đăng ký tài khoản',
  'Company': 'Tên công ty',
  'Your Name': 'Họ tên',
  'ABC Company': 'họ và tên của bạn',
  'abcd@gmail.com': 'nhập email của bạn',
  'Send me LuckyDraw newsletters': 'Gửi cho tôi các bản tin LuckyDraw (3 email / 365 ngày)',
  'Return to Login form': 'Quay trở lại form đăng nhập',
  'Return to': 'Bạn đã có tài khoản?',
  'Login form': 'Đăng nhập',
  'Already have an account?': 'Bạn đã có tài khoản?',
  'Login / Register': 'Đăng nhập',
  'You can create your own lucky drawing list after registration': 'Bạn có thể tự tạo danh sách quay thưởng sau khi đăng ký tài khoản',

  // forgot password
  'Forgot password': 'Quên mật khẩu',
  'Send email': 'Gửi Email',
  'Reset Password': 'Khôi phục mật khẩu',
  'Please check your email for reset password instruction': 'Hướng dẫn khôi phục mật khẩu đã được gửi tới email của bạn. Vui lòng kiểm tra và làm theo hướng dẫn để thiết lập lại mật khẩu và đăng nhập vào tài khoản của bạn.',
  'Enter your registered email': 'Nhập email bạn đã dùng khi đăng ký tài khoản',

  // logout
  'Logout': 'Đăng xuất',

  'Exit': 'Thoát',
  'Finish': 'Kết thúc',

  // account info
  'Account Info': 'Tài khoản',
  'Account information': 'Tài khoản của tôi',
  'Account Type': 'Loại tài khoản',
  'Paid Account': 'VIP',
  'Free Account': 'Thử nghiệm',
  'Expired Account': 'Hết hạn sử dụng',
  'Last Payment': 'Ngày nâng cấp',
  'Expiration Date': 'Ngày hết hạn',
  'Change Password': 'Đổi mật khẩu',
  'Current password': 'Mật khẩu hiện tại',
  'New password': 'Mật khẩu mới',
  'Re-enter': 'Nhập lại',
  'Logo': 'Logo',
  'Pay to Extend': 'Gia hạn Sử dụng',
  'Pay to Upgrade': 'Nâng cấp Tài khoản',
  'Pay Now': 'Thanh Toán',

  // theme
  'Change theme': 'LuckyDraw Theme',
  'Select a color theme that match your brand or event': 'Thay đổi màu sắc phù hợp với thương hiệu, sự kiện của bạn',

  // language
  'Change language': 'Language',

  // background
  'Change Background': 'Thay đổi hình nền',
  'Upload New Background': 'Upload hình mới',
  'Remove Background': 'Xóa hình nền',

  // contact us
  'Contact us': 'Liên hệ / Hỗ trợ',
  'Contact Name': 'Người liên hệ',
  'Inquiry': 'Nội dung',
  'Submit': 'Gửi đi',
  'Your message has been sent successfully to LuckyDraw team!': 'Yêu cầu của bạn đã được gửi tới LuckyDraw team!',
  'Unable to send your message': 'Xin lỗi bạn, chức năng gửi yêu cầu tạm thời bị gián đoạn. Vui lòng gửi yêu cầu qua email tới địa chỉ hi@luckydraw.live',

  // theme
  'Theme Purple': 'Tím',
  'Theme Navy': 'Xanh Navy',
  'Theme Olive': 'Màu Olive',
  'Theme Orange': 'Cam',
  'Theme Black': 'Đen',
  'Theme Blue': 'Xanh da trời',
  'Theme Green': 'Xanh lá cây',
  'Theme Red': 'Đỏ',
  'Theme Silver': 'Bạc',
  'Theme Aqua': 'Màu Aqua',
  'Theme Yellow': 'Vàng',
  'Theme White': 'Trắng',

  // date time format
  'DateTimeFormat': 'DD/MM/YYYY, HH:mm:ss',
  'shortDateTimeFormat': 'DD/MM/YYYY',

  // alert
  'Done': 'Hoàn thành',
  'Info': 'Thông báo',

  // guide
  'guide_shortcut_Enter': 'Phím tắt: bấm Enter trên bàn phím để Quay số',
  'guide_shortcut_EnterAgain': 'Nhấn Enter lần nữa để Chốt số',
  'guide_shortcut_AcceptCancel': 'Nhấn phím + để Xác nhận; phím - để Quay lại',
  'guide_shortcut_LeftRight': 'Dùng phím Trái/Phải hoặc 4/6 để chọn giải',
  'guide_Completed': 'Tuyệt vời! Bạn hãy đăng nhập để tải danh sách quay số riêng.',
  'Reload to Update New Version': 'Có bản cập nhật mới của LuckyDraw! Vui lòng nhấn đồng thời hai phím Ctrl+F5 để tải lại trang web với các tính năng mới nhất.',
  'Please open customize page with WiFi': 'Vui lòng truy cập trang này khi đang có kết nối Internet để tải dữ liệu trước, nếu không chế độ quay số offline sẽ không thể hoạt động.',
  'Not Editable When Disconnected': 'Bạn cần kết nối mạng để có thể cập nhật dữ liệu.',
  'Not Doable When Disconnected': 'Bạn cần kết nối mạng để thực hiện thao tác này.',
  'Disconnected': 'Bạn đang bị mất kết nối Internet!',
  'Only Switch to Presentation when Online': 'Vui lòng kết nối Internet trước khi chuyển sang chế độ Quay số (Trình chiếu). Sau đó bạn có thể ngắt kết nối và phần mềm vẫn hoạt động bình thường.',
  'Only Exit Presentation when Online': 'Để ngừng chế độ Quay số bạn cần kết nối máy tính vào mạng Internet.'
}
