"use strict";
angular
    .module('app')

    /**
     * defaultIdService
     */
    .factory('defaultIdService', function($translate) {
        var initialId = {
            vi: {
                "137481": "Trần Phú Quang",
                "144364": "Kiều Thanh Sơn",
                "146729": "Nguyễn Thị Thu Trang",
                "152268": "Phạm Năng Thành",
                "154756": "Lê Duy Khang",
                "174993": "Cao Xuân Hà",
                "177793": "Đỗ Anh Tú",
                "181545": "Lê Thị Bảo Ngân",
                "181840": "Vương Minh Thái",
                "187918": "Nguyễn Ngọc Khánh",
                "199515": "Nguyễn Duy Đông",
                "208694": "Trần Mạnh Tiến",
                "211567": "Phạm Minh Hải",
                "212656": "Nguyễn Thị Thủy Anh",
                "213340": "Bùi Hoàng Hà",
                "215002": "Nguyễn Thành Công",
                "215206": "Trịnh Văn Phúc",
                "215307": "Luân Đức Thuận",
                "216249": "Nguyễn Ngọc Triệu",
                "216445": "Lê Phương Thảo",
                "223470": "Trịnh Thị Lê",
                "227877": "Bùi Văn Sơn",
                "230471": "Lê Trần Trung Hiếu",
                "231414": "Nguyễn Quang Long",
                "234478": "Bùi Viết Khải",
                "235366": "Nguyễn Bá Cương",
                "236085": "Vùi Thị Quyền",
                "236201": "Nguyễn Tuấn Dũng",
                "237984": "Nguyễn Như Đạt",
                "253777": "Nguyễn Thanh Sơn",
                "254945": "Nguyễn Gia Đăng",
                "255140": "Ngô Việt Dương",
                "260200": "Phạm Gia Huy",
                "261713": "Phan Tuấn Anh",
                "265576": "Chu Kim Vang",
                "266376": "Vũ Trần Phương Nam",
                "270273": "Trần Thị Thùy Giang",
                "272373": "Nguyễn Đức Quang",
                "276032": "Nguyễn Trọng Việt",
                "276963": "Mai Văn Bình",
                "277481": "Đoàn Ngọc Quang",
                "279025": "Nguyễn Văn Tưởng",
                "279287": "Nguyễn Thành Trung",
                "279701": "Nguyễn Hồng Trung",
                "283769": "Nguyễn Văn Phú",
                "284779": "Đàm Thị Ngân",
                "286842": "Nguyễn Minh Tuấn",
                "292367": "Lê Việt Anh",
                "296228": "Nguyễn Hoàng Tiến",
                "298694": "Đào Khánh Linh",
                "300091": "Nguyễn Thị Bích Ngọc",
                "303526": "Đào Trung Kiên",
                "305988": "Đặng Thị Mai",
                "307964": "Lê Thị Thủy",
                "309309": "Đinh Văn Chiến",
                "315652": "Nguyễn Nhật Hồng",
                "315706": "Nguyễn Đức Minh",
                "321177": "Trịnh Minh Đức",
                "325766": "Lê Thị Thảo",
                "327532": "Bùi Thế Vũ",
                "334536": "Nguyễn Thành Trung",
                "340102": "Phạm Quang Huy",
                "346858": "Trần Ngọc Sơn",
                "347194": "Trần Đức Mạnh",
                "347577": "Lê Thế Tình",
                "349226": "Đào Bá Huỳnh",
                "349380": "Nguyễn Thị Dung",
                "349704": "Nguyễn Việt Cường",
                "350360": "Nguyễn Hưng Tuấn",
                "354039": "Trần Văn Duy",
                "364991": "Nguyễn Văn Quý",
                "370571": "Nguyễn Thành Nam",
                "376616": "Trần Thị Thu Hương",
                "378632": "Nguyễn Văn Trọng",
                "379235": "Nguyễn Bá Hùng",
                "379683": "Đặng Quốc Đạt",
                "380507": "Bùi Minh Nghĩa",
                "382098": "Nguyễn Văn Vương",
                "387785": "Phùng Tiến Thành",
                "390904": "Phạm Thùy Linh",
                "393921": "Lương Trung Tín",
                "396264": "Phan Xuân Đông",
                "400555": "Phạm Minh Tuấn",
                "402517": "Lều Đức Anh",
                "407581": "Nguyễn Thị Lan",
                "407591": "Ngô Văn Hiến",
                "409532": "Hoàng Thị Chinh",
                "411091": "Tăng Tuấn Anh",
                "416790": "Lê Thị Phúc",
                "421536": "Vũ Diệu Ngọc",
                "421925": "Trịnh Thị Ánh Tuyết",
                "423336": "Nguyễn Thị Phượng",
                "424186": "Nguyễn Minh Vương",
                "434924": "Đào Thị Uyên",
                "435143": "Nguyễn Bá Trịnh",
                "437006": "Lê Thị Ngọc Châm",
                "439847": "Trần Mỹ Chiến",
                "440899": "Nguyễn Minh Quang",
                "446745": "Trần Thị Hiền",
                "446929": "Vũ Liên Hương",
                "447982": "Hoàng Anh Tuấn",
                "449896": "Trần Đăng Hoàng",
                "450209": "Lê Cao Nguyên",
                "450992": "Nguyễn Như Tuân",
                "454244": "Đào Mạnh Hùng",
                "456245": "Nguyễn Thị Hồng Thúy",
                "457424": "Nguyễn Anh Tuấn",
                "465368": "Trần Văn Đạt",
                "466612": "Vũ Huy Hoàng",
                "467676": "Phạm Thị Hằng",
                "468043": "Hoàng Thị Huyền",
                "470130": "Trịnh Văn Thành",
                "473670": "Trịnh Kim Long",
                "475006": "Lê Công Linh",
                "475081": "Đỗ Hữu Tuấn",
                "477690": "Nguyễn Thị Thùy Trang",
                "480770": "Ngô Thị Thu Hồng",
                "481649": "Đinh Văn Hợp",
                "482085": "Nguyễn Thị Huế",
                "483133": "Nguyễn Ngọc Lâm",
                "486774": "Bùi Thành Lộc",
                "488727": "Lê Tây Trọng",
                "494136": "Nguyễn Thọ Bảo",
                "495770": "Nguyễn Trường Chinh",
                "499781": "Vũ Công Văn",
                "501990": "Nguyễn Danh Quyết",
                "502435": "Vũ Thành Cung",
                "502663": "Nguyễn Quỳnh Anh",
                "502735": "Đào Anh Sang",
                "503457": "Nguyễn Đăng Lam",
                "505060": "Phạm Đình Khánh",
                "507516": "Trần Anh Minh",
                "507995": "Hoàng Hồng Phúc",
                "510923": "Nguyễn Thị Hồng",
                "525081": "Nguyễn Tá Anh",
                "525837": "Nguyễn Thị Ngọc Huyền",
                "526838": "Nguyễn Đình Triều",
                "533850": "Nguyễn Văn Thao",
                "534571": "Nguyễn Quang Khải",
                "534723": "Lê Quỳnh Phước",
                "539524": "Trần Văn Tuyên",
                "541048": "Trần Văn Tín",
                "542329": "Lê Văn Trường",
                "544289": "Trần Trọng Nguyên",
                "547511": "Trần Thị Lan Anh",
                "551497": "Đặng Thanh Tùng",
                "551586": "Bùi Văn Vượng",
                "553286": "Nguyễn Đăng Trung",
                "554011": "Nguyễn Hữu Tuấn",
                "556355": "Nguyễn Văn Tiến",
                "558276": "Vũ Thị Thu Hà",
                "559277": "Lê Đắc Kiên",
                "563983": "Lê Minh Hoàng",
                "567325": "Mai Thị Phương Thảo",
                "567534": "Nguyễn Văn Phát",
                "567979": "Trần Hà Ngọc Thiện",
                "568926": "Nguyễn Văn Đại",
                "572674": "Lê Trường Giang",
                "573923": "Đoàn Văn Chiến",
                "578071": "Nguyễn Trung Kiên",
                "581880": "Trần Xuân Quang",
                "591574": "Nguyễn Hồng Nhu",
                "591635": "Nguyễn Trung Long",
                "599529": "Nguyễn Bá Ngọc",
                "600737": "Bùi Văn Du",
                "601459": "Lê Bá Hoàng",
                "604425": "Giang Hùng Việt",
                "613145": "Phan Thị Hà Trang",
                "613697": "Nguyễn Huy Tiến",
                "617191": "Đinh Thị Vân Trang",
                "619158": "Vũ Thị Lĩnh",
                "620598": "Nguyễn Thế Anh",
                "620714": "Vũ Thị Nhung",
                "620878": "Nguyễn Viết Đức",
                "623243": "Nguyễn Thanh Huyền",
                "624940": "Lại Thị Ngọc",
                "626404": "Đào Thị Hải Ninh",
                "626918": "Nguyễn Thanh Tuấn",
                "632387": "Trần Thị Tuyết",
                "633001": "Nguyễn Hữu Hoàn",
                "633489": "Trương Văn Long",
                "635725": "Hoàng Mạnh Tấn",
                "639789": "Trần Văn Sáng",
                "645903": "Văn Phú Điệp",
                "655873": "Khổng Thị Mai Hương",
                "657913": "Nguyễn Thị Thu Hương",
                "658285": "Phạm Hải Yến",
                "658872": "Phùng Mạnh Cường",
                "659085": "Bùi Toàn Thắng",
                "660824": "Bùi Thị Thùy Dương",
                "661198": "Trần Thị Hồng Liễu",
                "665413": "Hoàng Quốc Quân",
                "667068": "Nguyễn Công Cường",
                "668092": "Nguyễn Tiến Đạt",
                "671856": "Phạm Văn Tùng",
                "673211": "Đào Thị Thu Huyền",
                "675145": "Đào Đức Thành",
                "678436": "Nguyễn Trần Nam Anh",
                "679155": "Viên Tuấn Hùng",
                "679698": "Lê Trọng Hòa",
                "682310": "Phạm Duy Tùng",
                "684794": "Nguyễn Thị Thúy Nga",
                "685527": "Nguyễn Văn Hà",
                "685935": "Mai Đức Toàn",
                "687290": "Nguyễn Văn Phượng",
                "691152": "Phạm Thị Thu",
                "696044": "Trần Thanh Sơn",
                "697500": "Nguyễn Tiến Dũng",
                "700497": "Trương Trần Tuấn",
                "709110": "Nguyễn Tuấn Anh",
                "713562": "Nguyễn Duy Khánh",
                "713616": "Nguyễn Hoàng Nam",
                "715168": "Phạm Thị Gấm",
                "718143": "Nguyễn Quyết Thắng",
                "720638": "Cao Ngọc Anh",
                "726672": "Trần Thị Thiết",
                "728348": "Tống Đình Đồng",
                "732898": "Bạch Hồng Quân",
                "736691": "Phùng Thị Diệu Vi",
                "739514": "Phạm Thúy Anh",
                "740496": "Phạm Đình Vương",
                "743575": "Trần Thúy Nga",
                "745279": "Phạm Đức Huy",
                "748369": "Nguyễn Thị Hiền",
                "749722": "Vũ Minh Hiếu",
                "751844": "Trần Ngọc Vượng",
                "756682": "Nguyễn Đức Thanh",
                "759290": "Bùi Công Trình",
                "760100": "Khuất Thị Ngọc Trâm",
                "761254": "Nguyễn Bắc Hải",
                "762319": "Đặng Thị Ngọc Trâm",
                "762968": "Phí Hà Duy",
                "767360": "Nguyễn Lan Anh",
                "772304": "Trịnh Quốc Anh",
                "774647": "Nguyễn Xuân Trường",
                "775011": "Nguyễn Huy Cường",
                "775928": "Nguyễn Văn Ly",
                "789879": "Tạ Anh Tuấn",
                "791198": "Nguyễn Khắc Dũng",
                "792263": "Ngô Đình Tạo",
                "798895": "Phạm Trung Nguyên",
                "807437": "Nguyễn Công Nam",
                "809211": "Nguyễn Hữu Đức",
                "811562": "Đặng Văn Đà",
                "814843": "Nguyễn Quý Đạt",
                "816411": "Bùi Trung Nghĩa",
                "816812": "Lê Duy Hiệp",
                "819836": "Nguyễn Quốc Học",
                "820836": "Ngô Thị Hiền",
                "826644": "Nguyễn Thị Thu Hà",
                "828039": "Nguyễn Duy Phong",
                "830129": "Lê Anh Hào",
                "833243": "Nguyễn Hoài Nam",
                "836084": "Nguyễn Thu Hường",
                "837364": "Lê Văn Cảnh",
                "840075": "Trịnh Mạnh Dũng",
                "843694": "Nguyễn Thị Hương",
                "847978": "Nguyễn Đức Anh Tuấn",
                "849206": "Trần Thị Thu Hằng",
                "850415": "Trịnh Đức Duy",
                "851314": "Đào Hải Yến",
                "854077": "Lương Thị Lệ Chi",
                "855635": "Tống Tuấn Anh",
                "858621": "Nguyễn Hà Quy",
                "861673": "Nguyễn Sỹ Tài",
                "868106": "Nguyễn Kim Long",
                "868176": "Nguyễn Thị Diệu Linh",
                "868260": "Nguyễn Hữu Tiến",
                "871844": "Trần Đức Hùng",
                "872367": "Lương Văn Tuyên",
                "874028": "Trịnh Thị Thu Hường",
                "877015": "Đặng Việt Hưng",
                "878471": "Lê Việt Trung",
                "879219": "Nguyễn Đình Đoạt",
                "882020": "Trần Ngọc Sơn",
                "887609": "Nguyễn Khắc Quả",
                "889079": "Nghiêm Quang Duy",
                "890158": "Lưu Thị Minh Thúy",
                "892354": "Dương Bá Tùng",
                "893870": "Trần Mạnh Linh",
                "895941": "Bùi Công Hoàng",
                "899733": "Trần Văn Thắng",
                "903604": "Hồ Đức Hòa",
                "904319": "Phạm Văn Kim",
                "910952": "Vũ Đăng Trong",
                "910989": "Nguyễn Thị Mai",
                "913153": "Bùi Ngọc Anh",
                "914819": "Phạm Tiến Anh",
                "916678": "Nghiêm Xuân Tá",
                "926111": "Bùi Xuân Lai",
                "930678": "Trương Thị Thùy",
                "933511": "Bùi Đức Bình",
                "933583": "Phạm Thị Phương Hoa",
                "933979": "Lê Hữu Dũng",
                "939222": "Đinh Xuân Trung",
                "941879": "Phạm Hoàng Long",
                "944848": "Phạm Thành Tâm",
                "945598": "Nguyễn Văn Toàn",
                "951452": "Lê Ngọc Toàn",
                "951588": "Lê Văn Biên",
                "953564": "Nguyễn Đăng Thịnh",
                "954821": "Nguyễn Thị Diệm",
                "960304": "Lê Thị Ngân",
                "962650": "Nguyễn Hồng Hải",
                "963240": "Đào Minh Đức",
                "968254": "Nguyễn Minh Hoàng"
            },
            en: {
                "137481": "Steve J. Zhang",
                "144364": "Emma Wood",
                "146729": "Janet C. Hernandez",
                "152268": "Kellie W. Jimenez",
                "154756": "Katherine M. Murphy",
                "169700": "Lisa J. He",
                "174993": "Kendra Torres",
                "177793": "Eddie Blanco",
                "181545": "Stephanie Lopez",
                "181840": "Sabrina P. Ortega",
                "183951": "Gabriel K. Edwards",
                "186545": "Casey Bhat",
                "187918": "Eduardo B. Wright",
                "199515": "Ramon Wang",
                "202380": "Jonathan W. Simmons",
                "208694": "Damien R. Gao",
                "211567": "Nancy L. Madan",
                "212656": "Levi D. Srini",
                "213340": "Priscilla Xie",
                "215002": "Darren Gomez",
                "215206": "Sydney L. Martin",
                "215307": "Lucas N. James",
                "216249": "Luis D. King",
                "221861": "Sebastian Rivera",
                "222075": "Gabrielle Richardson",
                "223470": "Cassie Pal",
                "227877": "Devin E. Scott",
                "230471": "Orlando K. Navarro",
                "231414": "Ann Perez",
                "234478": "Candace R. Lopez",
                "235366": "Evan L. Morris",
                "236085": "Casey Romero",
                "236201": "Jade R. Ramirez",
                "237984": "Alyssa E. Williams",
                "238811": "Cindy Chandra",
                "243937": "Dakota A. Coleman",
                "250176": "Alicia Chande",
                "253777": "Julia Scott",
                "254945": "Adam Coleman",
                "255140": "Kristi A. Alonso",
                "260200": "Lacey Nath",
                "261713": "Rosa Xu",
                "265576": "David Sharma",
                "266376": "Gregory C. Deng",
                "270273": "Stacy Romero",
                "272373": "Riley Ross",
                "276032": "Terrance M. Patel",
                "276963": "Andre A. Rodriguez",
                "277481": "Mario C. Yuan",
                "279025": "Marshall Zhang",
                "279287": "Terrance G. Verma",
                "283769": "Paula L. Suarez",
                "284779": "Hunter J. Alexander",
                "286842": "Jarrod Sai",
                "288273": "Anna Wilson",
                "292367": "Arturo J. Bhat",
                "296228": "Brianna Wilson",
                "300091": "Mitchell Tang",
                "303526": "Darryl S. Huang",
                "305988": "Alexa Ramirez",
                "307964": "Andre R. Fernandez",
                "309309": "Roy A. Gomez",
                "315652": "Christy Sharma",
                "315706": "Tanya Hernandez",
                "321177": "Caitlin C. Cook",
                "327532": "Hunter Martin",
                "334536": "Marshall Nath",
                "340102": "Shawna M. Nath",
                "342889": "Lauren Stewart",
                "346858": "Jennifer R. Flores",
                "349226": "Haley C. Baker",
                "349380": "Jon Xie",
                "349704": "Steven S. Ward",
                "350360": "Alexis M. Jackson",
                "354039": "Angel C. Bell",
                "370571": "Sydney E. Griffin",
                "376616": "Olivia R. Ross",
                "378632": "Megan R. Perry",
                "379235": "Connor F. Carter",
                "379683": "Hailey P. Sanchez",
                "380507": "Jose Powell",
                "387785": "Derek C. Aggarwal",
                "390904": "Ruth A. Kapoor",
                "393921": "Zachary Li",
                "396264": "Monica Engineer",
                "396578": "Andy Ruiz",
                "400555": "Jeremiah E. Williams",
                "402517": "Patrick Cooper",
                "407581": "Christina Kelly",
                "407591": "Rachel W. Watson",
                "409532": "Albert Gutierrez",
                "411091": "Samuel Li",
                "416790": "Trinity Peterson",
                "421925": "Dalton E. Allen",
                "423336": "Melinda B. Munoz",
                "424186": "Noah B. Li",
                "434924": "Michele Alonso",
                "435143": "Denise Sai",
                "437006": "Christine Lal",
                "439164": "Miranda Perry",
                "439847": "Adam R. Yang",
                "440899": "Elizabeth Foster",
                "446745": "Caroline Jenkins",
                "446929": "Caleb Jai",
                "447982": "Leah Zhao",
                "449896": "Arianna M. Perry",
                "450209": "Bryan C. Peterson",
                "450992": "Erick L. Raman",
                "454244": "Sheila T. Jimenez",
                "456245": "Tabitha A. Rubio",
                "457424": "Raquel Jimenez",
                "465368": "Billy Ramos",
                "466612": "Jason M. Henderson",
                "467676": "Angel L. Collins",
                "468043": "Sheila Dominguez",
                "470130": "Felicia J. Hernandez",
                "473670": "Douglas Chandra",
                "475006": "Michael B. Johnson",
                "475081": "Dylan L. Johnson",
                "477690": "Christopher C. Thompson",
                "480770": "Ariana J. Torres",
                "481649": "Jared E. Cooper",
                "482085": "Damien D. Kumar",
                "483133": "Arthur A. Arun",
                "486774": "Darrell K. Nara",
                "488727": "Rachael J. Garcia",
                "494136": "Lauren C. Martin",
                "495770": "Marie Hernandez",
                "499781": "Matthew Thompson",
                "499995": "Hunter A. Green",
                "501786": "Tammy Subram",
                "501990": "Orlando Ruiz",
                "502435": "Cedric C. Aggarwal",
                "502663": "Anna S. Bennett",
                "502735": "Henry Sai",
                "503457": "Frederick Mehta",
                "505060": "Roger Li",
                "507516": "Terry Goel",
                "507995": "Joe Munoz",
                "510426": "Christine S. Sharma",
                "510923": "Cameron L. Martin",
                "522823": "Julie L. Rai",
                "525081": "Donald J. Rana",
                "525837": "Julia E. Allen",
                "526838": "Byron Munoz",
                "534571": "Leonard L. Sharma",
                "534723": "Gloria W. Hernandez",
                "535090": "Victoria Robinson",
                "541048": "Gloria Moreno",
                "542329": "Adriana Fernandez",
                "544289": "Walter W. Blanco",
                "547511": "Barbara Shan",
                "551497": "Shawn A. Chande",
                "551586": "Grant M. Chande",
                "554011": "Makayla Richardson",
                "556355": "Angela Bryant",
                "558276": "Amanda Reed",
                "559277": "Xavier A. Reed",
                "563983": "Connor J. Long",
                "565724": "Kristen Liang",
                "567325": "Paula A. Torres",
                "567979": "Adrienne Moreno",
                "568926": "Mary I. Campbell",
                "572674": "Vincent G. Wu",
                "573923": "Clifford D. Rana",
                "578071": "Michele T. Munoz",
                "585363": "Isaac Stewart",
                "586089": "Todd A. Wu",
                "591635": "Bryce K. Kelly",
                "600737": "Mason C. Morgan",
                "601459": "Adrienne E. Castro",
                "604425": "Corey M. Xu",
                "613697": "Isabella Wilson",
                "619158": "Arturo F. She",
                "620598": "Monica Suri",
                "620714": "Kurt E. Nath",
                "620878": "Aimee E. Xu",
                "623243": "Henry F. Malhotra",
                "627546": "Savannah J. Cooper",
                "633001": "Caitlin C. Gray",
                "633489": "Nathan Gonzalez",
                "635725": "Carolyn L. Jimenez",
                "639789": "Lindsey S. She",
                "645903": "Courtney Roberts",
                "655873": "Peter Yuan",
                "656317": "Marshall Zheng",
                "657913": "Tonya M. Yuan",
                "658285": "Louis A. Sharma",
                "658872": "Mya K. Hughes",
                "660824": "Bianca A. Sun",
                "661198": "Sydney E. Taylor",
                "665413": "Kyle Allen",
                "667068": "Bailey Edwards",
                "668092": "Jamie N. Navarro",
                "668384": "Ernest Zheng",
                "671856": "Charles Campbell",
                "673211": "Edwin M. Ma",
                "675145": "Terry L. Anand",
                "678436": "Brittney Yang",
                "679155": "Steve A. Zhu",
                "679698": "Emmanuel E. Mehta",
                "682310": "Derek Deng",
                "684794": "Chris Kelly",
                "685527": "Robyn Martin",
                "685935": "Geoffrey K. Srini",
                "687290": "Cesar S. Patel",
                "691152": "Abby Srini",
                "697500": "Adrienne Alonso",
                "700497": "Carmen Rodriguez",
                "709110": "Miguel M. Lopez",
                "713562": "Connor S. Patterson",
                "713616": "Kristin K. Lal",
                "715168": "Curtis G. Ye",
                "718143": "Brad P. Shen",
                "720638": "Xavier Phillips",
                "728348": "Andrea C. Rivera",
                "732570": "Carson E. Ross",
                "732898": "Christy C. Anand",
                "739514": "Victor L. Alonso",
                "740496": "Autumn Ye",
                "743575": "Melvin Lal",
                "745279": "Jermaine Srini",
                "748369": "Martha R. Cai",
                "749722": "Stacy P. Sanz",
                "751844": "Deborah L. Bhat",
                "756682": "Brandon B. Hughes",
                "759290": "Melody P. Alonso",
                "760100": "Pedro W. Alonso",
                "761254": "Tyler Thomas",
                "762968": "Sophia C. Collins",
                "767360": "Hannah A. Miller",
                "772304": "Nicolas M. Nath",
                "775011": "Jerome M. Alvarez",
                "775928": "Bethany S. Sharma",
                "791198": "Toni C. Chandra",
                "792263": "Philip W. Vazquez",
                "794770": "Tracy Anand",
                "798895": "Raul L. Anand",
                "807437": "Catherine D. Gray",
                "809211": "Jessie C. Lin",
                "811101": "Sarah A. Jenkins",
                "811562": "Janelle M. Verma",
                "812337": "Devin B. Gonzales",
                "814843": "Javier M. Moreno",
                "816411": "Logan A. Russell",
                "816812": "Christine Kelly",
                "819836": "Henry A. Rodriguez",
                "820836": "Joe J. Ruiz",
                "826644": "Christy She",
                "828039": "Kevin E. Allen",
                "830129": "Ronnie Zhou",
                "833243": "Marco B. Sanchez",
                "836084": "Anthony P. Smith",
                "837364": "Monique Alvarez",
                "840075": "Pedro R. Castro",
                "843694": "Dalton Price",
                "849206": "Lacey R. Xie",
                "850415": "Blake L. Smith",
                "851314": "Jasmine K. Howard",
                "854077": "Nelson Munoz",
                "855635": "Stanley Kapoor",
                "858621": "Candace Engineer",
                "861673": "Edward G. Allen",
                "868106": "Meeghan A. Gonzalez",
                "868176": "Gina Ramos",
                "868260": "Tabitha Malhotra",
                "871844": "Trevor L. Griffin",
                "872367": "Sarah Hayes",
                "874028": "Damien P. Wu",
                "877015": "Kelli Zheng",
                "878471": "Ross Chandra",
                "879219": "Erica Li",
                "882020": "Ross Gomez",
                "887609": "Gilbert Lu",
                "889079": "Kristin Sharma",
                "890158": "Geoffrey Mehta",
                "890532": "Jamie Gil",
                "892354": "Kristin Raje",
                "895941": "Patricia L. Sai",
                "903604": "Angel M. Hernandez",
                "904319": "Josue C. Ortega",
                "910952": "Devon S. Nath",
                "910989": "Clarence R. Tang",
                "913153": "Julie J. Raji",
                "914819": "Olivia Bell",
                "916678": "Sebastian W. Kelly",
                "928360": "Shaun R. Xie",
                "930678": "Dawn L. Liang",
                "931306": "Joel E. Suri",
                "933511": "Victor Navarro",
                "933583": "Claudia J. Wu",
                "933979": "Jon M. Anand",
                "936785": "Hailey J. Wright",
                "939222": "Kimberly A. Morris",
                "941879": "Calvin C. Pal",
                "944848": "Trinity B. Ward",
                "945598": "Bianca E. She",
                "951452": "Jon C. Raji",
                "951588": "Shane A. Perez",
                "953564": "Tammy Perez",
                "954821": "Madeline L. Wright",
                "960304": "Denise L. Garcia",
                "962650": "Robert R. Hughes",
                "963240": "Isabel Hughes",
                "966544": "Ricky A. Ortega",
                "968254": "Gilbert D. Chande"
            }
        };

        return function() {
            var language = $translate.use();
            var ids = initialId[language] || initialId.en;
            return {
                ids: _.keys(ids),
                names: _.values(ids)
            }
        }

    });